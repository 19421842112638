<!--
 * @Description: 项目报备统计
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-06-13 15:52:03
-->
<template>
  <el-card class="box query">
    <el-form :model="query" ref="query" :inline="true" @submit.prevent="onSearch">
      <el-form-item label="项目名称">
        <el-input
          v-model="query.q"
          placeholder=""
          clearable
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="query.dates"
          type="daterange"
          value-format="YYYY-MM-DD"
          unlink-panels
          :clearable="false"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search" native-type="submit"
          >查询</el-button
        >

        <el-tooltip
          class="box-item"
          effect="dark"
          content="下载查询结果数据"
          placement="top"
        >
          <el-button
            icon="Download"
            v-power="'admin/v1/reportAnalysis/estate/stat/export_excel'"
            @click="downloadExcel"
            :loading="downloading"
            >下载数据</el-button
          >
        </el-tooltip>
      </el-form-item>
    </el-form>
    <div></div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border stripe>
      <el-table-column
        label="项目"
        prop="estateName"
        width="300"
      ></el-table-column>
      <el-table-column
        label="总数"
        prop="totalCounts"
      ></el-table-column>
      <el-table-column label="德佑总数" prop="deyooCounts"></el-table-column>
      <el-table-column label="分销总数" prop="fxCounts"></el-table-column>
      <el-table-column label="结果未知总数" prop="unknownCounts"></el-table-column>
      <el-table-column label="有效总数" prop="validCounts"></el-table-column>
      <el-table-column label="无效总数" prop="invalidCounts"></el-table-column>
      <el-table-column label="已带看总数" prop="arrivedCounts"></el-table-column>
      <el-table-column label="已成交总数" prop="successCounts"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
</template>

<script>
import fileDownload from "js-file-download";
export default {
  data() {
    return {
      loading: false,
      downloading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        sort: {
          order: "",
          prop: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
    };
  },
  created() {
    this.query.dates = [
      this.$moment().add(-1, "months").format("YYYY-MM-DD"),
      this.$moment().format("YYYY-MM-DD"),
    ];

    this.loadData();
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     *
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("/admin/v1/reportAnalysis/estate/stat", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 下载数据
     */
    downloadExcel() {
      this.downloading = true;
      var arg = Object.assign({}, this.query);
      delete arg.storeIds;
      if (
        Array.isArray(this.query.storeIds) &&
        this.query.storeIds.length > 0
      ) {
        arg.store_ids = this.query.storeIds.map((x) => {
          return x[1];
        });
      }
      this.$http
        .post("admin/v1/reportAnalysis/estate/stat/export_excel", arg, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res, "项目报备统计.xlsx");
        })
        .finally(() => {
          this.downloading = false;
        });
    },
  },
};
</script>

<style scoped></style>
